<script>
import axios from "axios";
import { getGraduationList } from "@/helpers/get-graduation-list";
import paymentMethods from "@/constants/payment-methods";
import MemberCreateTemplate from "../templates/MemberCreateTemplate.vue";

export default {
  components: { MemberCreateTemplate },
  name: "MemberCreatePage",
  data: () => ({
    addressQuery: {
      street: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: "",
    },
    paymentMethods,
    graduationList: [],
    step: 1,
    form: {},
    dialog: {
      birthday: false,
      paymentReference: false,
      paymentDate: false,
      registerDate: false,
    },
    show: true,
    formMode: "new",
  }),
  created() {
    this.addressQuery = {
      street: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: "",
    };
  },
  mounted() {
    this.graduationList = getGraduationList(this.genre);
  },
  computed: {
    academyId() {
      return this.$store.state.academy.currentAcademy.id;
    },
    genre: function () {
      return this.$store.state.academy.currentAcademy.genre;
    },
  },
  methods: {
    // TODO: Move to service
    // Call from WHERE?
    // How to feed this info?
    async checkCEP(payload) {
      let pureCep = payload.replaceAll("-", "");
      if (pureCep.length == 8) {
        /* this.$store.dispatch("loading", true); */
        await axios
          .get("https://viacep.com.br/ws/" + pureCep + "/json/")
          .then(async (response) => {
            if (response != undefined) {
              this.addressQuery.street = response.data.logradouro;
              this.addressQuery.complement = response.data.complemento;
              this.addressQuery.neighborhood = response.data.bairro;
              this.addressQuery.city = response.data.localidade;
              this.addressQuery.state = response.data.uf;
              this.addressQuery.cep = response.data.cep;
            }
          });
        /* this.$store.dispatch("loading", false); */
      }
    },
    async submit(payload) {
      let academy = {
        isActive: payload.academy.isActive,
        register: payload.academy.registerDate,
      };
      let billing = {
        date: payload.academy.lastBillingDate,
        reference: payload.academy.lastBillingReference,
        method: payload.academy.lastBillingMethod,
        price: payload.academy.lastBillingValue,
        academyId: this.academyId ? this.academyId : "",
      };

      let newMember = {
        name: payload.basic.name,
        accessLevel: "user",
        graduation: payload.academy.graduation,
        contact: {
          phone: payload.basic.phone,
          email: payload.basic.email,
        },
        personal: {
          birthday: payload.personal.birthday,
          gender: payload.personal.gender,
          rg: payload.personal.rg,
          rgOrgan: payload.personal.rgOrgan,
          cpf: payload.personal.cpf,
          nameMother: payload.personal.nameMother,
          nameFather: payload.personal.nameFather,
          nationality: payload.personal.nationality,
        },
        work: {
          scolarship: payload.work.scolarship,
          name: payload.work.name,
          phone: payload.work.phone,
        },
        address: {
          street: payload.address.street,
          complement: payload.address.complement,
          number: payload.address.number,
          neighborhood: payload.address.neighborhood,
          cep: payload.address.cep,
          city: payload.address.city,
          state: payload.address.state,
        },
        academies: {},
      };

      newMember.academies[this.academyId] = academy;

      let newPayload = {};
      newPayload.member = newMember;
      newPayload.billing = billing;

      await this.$store.dispatch("user/create", newPayload);

      /*  if (
        billing.date != undefined ||
        billing.reference != undefined ||
        billing.method != undefined ||
        billing.price != undefined
      ) {
        this.$store.dispatch("billing/create", billing);
      } else {
        this.$router.back();
      } */
    },
    async oneTimeLink(payload) {
      let academy = {
        isActive: payload.academy.isActive,
        register: payload.academy.registerDate,
      };
      let billing = {
        date: payload.academy.lastBillingDate,
        reference: payload.academy.lastBillingReference,
        method: payload.academy.lastBillingMethod,
        price: payload.academy.lastBillingValue,
        academyId: this.academyId ? this.academyId : "",
      };

      let newMember = {
        name: payload.basic.name,
        accessLevel: "user",
        graduation: payload.academy.graduation,
        contact: {
          phone: payload.basic.phone,
          email: payload.basic.email,
        },
        personal: {
          birthday: payload.personal.birthday,
          gender: payload.personal.gender,
          rg: payload.personal.rg,
          rgOrgan: payload.personal.rgOrgan,
          cpf: payload.personal.cpf,
          nameMother: payload.personal.nameMother,
          nameFather: payload.personal.nameFather,
          nationality: payload.personal.nationality,
        },
        work: {
          scolarship: payload.work.scolarship,
          name: payload.work.name,
          phone: payload.work.phone,
        },
        address: {
          street: payload.address.street,
          complement: payload.address.complement,
          number: payload.address.number,
          neighborhood: payload.address.neighborhood,
          cep: payload.address.cep,
          city: payload.address.city,
          state: payload.address.state,
        },
        academies: {},
      };

      newMember.academies[this.academyId] = academy;

      let newPayload = {};
      newPayload.member = newMember;
      newPayload.billing = billing;

      await this.$store.dispatch("user/oneTimeLinkMemberCreate", newPayload);

      /*  if (
        billing.date != undefined ||
        billing.reference != undefined ||
        billing.method != undefined ||
        billing.price != undefined
      ) {
        this.$store.dispatch("billing/create", billing);
      } else {
        this.$router.back();
      } */
    },
    cancelNewMember() {
      this.$router.push("/");
    },
  },
};
</script>

<template>
  <member-create-template
    :addressQuery="addressQuery"
    @checkCEP="checkCEP"
    @submit="submit"
    @oneTimeLink="oneTimeLink"
  />
</template>

<style lang="scss" scoped>
.container,
form {
  min-height: 100vh;
  min-width: 100%;
}
</style>
