<script>
import AppStepper from "@/components/atoms/AppStepper.vue";
import AppDialog from "@/components/atoms/AppDialog.vue";
import TheInnerToolbar from "@/components/organisms/TheInnerToolbar.vue";
import memberCreateForm from "@/constants/form/member-create-form";
import { getGraduationList } from "@/helpers/get-graduation-list";

export default {
  name: "MemberCreateTemplate",
  components: { AppStepper, AppDialog, TheInnerToolbar },
  methods: {
    submit(payload) {
      this.$emit("submit", payload);
    },
    cancelConfirm() {
      this.dialog = false;
      this.$router.push("/");
    },
    cancelCancel() {
      this.dialog = false;
    },
    cancel() {
      this.dialog = true;
    },
    handleChange(payload) {
      if (payload.address.cep != undefined && payload.address.cep.length > 0) {
        if (
          payload.address.cep.length === 9 &&
          payload.address.city.length === 0
        ) {
          this.$emit("checkCEP", payload.address.cep);
        }
      } else {
        let keys = Object.keys(this.stepList);
        keys.forEach((key) => {
          let fieldKeys = Object.keys(
            this.stepList[key][this.formKeys[key]].form
          );
          fieldKeys.forEach((field) => {
            this.stepList[key][this.formKeys[key]].form[field].value =
              payload[this.stepList[key][this.formKeys[key]].name][field];
          });
        });
      }
    },
    updateAddress(newAddress) {
      let addressKey = {};
      for (let addressFormKey in this.formKeys) {
        if (
          this.stepList[addressFormKey][this.formKeys[addressFormKey]].name ===
          "address"
        ) {
          addressKey = addressFormKey;
        }
      }

      if (Object.keys(newAddress).length > 0) {
        let addressKeys = Object.keys(newAddress);
        addressKeys.map((key) => {
          this.stepList[addressKey][this.formKeys[addressKey]].form[key].value =
            newAddress[key];
        });
      }

      document.activeElement.blur();
    },
    changeStep(payload) {
      this.step = payload;
    },
    oneTimeDialog(payload) {
      this.dialog2 = true;
      this.tempUser = payload;
    },
    oneTimeConfirm() {
      this.oneTimeLink();
    },
    oneTimeCancel() {
      this.dialog2 = false;
    },
    oneTimeLink() {
      this.$emit("oneTimeLink", this.tempUser);
    },
  },
  created() {
    /* this.formKeys = getFormObjectKeys(this.stepList); */

    if (Object.keys(this.addressQuery).length === 0) {
      Object.assign(this.addressQuery, this.blankAddress);
    }
    this.updateAddress(this.addressQuery);
  },
  mounted() {
    /*  let stepListForm = this.stepList[2].find((item) => item.type == "form"); */
    this.stepList[2][2].form.graduation.items = getGraduationList(this.genre);
  },
  computed: {
    hasMembers: function () {
      return this.$store.state.user.users.length > 0;
    },
    genre: function () {
      return this.$store.state.academy.currentAcademy.genre;
    },
  },
  props: {
    addressQuery: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    addressQuery: {
      // watch it
      handler: function (val) {
        this.updateAddress(val);
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      dialog: false,
      dialog2: false,
      tempUser: {},
      step: 1,
      blankAddress: {
        street: "",
        complement: "",
        neighborhood: "",
        city: "",
        state: "",
        cep: "",
      },
      stepList: JSON.parse(JSON.stringify(memberCreateForm)),
      formKeys: {
        1: "3",
        2: "2",
        3: "2",
        4: "2",
        5: "2",
      },
    };
  },
};
</script>

<template>
  <section id="member-create-container" class="section-container">
    <the-inner-toolbar
      title="Adicionar Membro"
      :isForm="true"
      @cancel="cancel"
    />
    <app-stepper
      @changeStep="changeStep"
      :currentStep="step"
      :stepList="stepList"
      :formKeys="formKeys"
      @change="handleChange"
      @cancel="cancel"
      @click="oneTimeDialog"
      @submit="submit"
    />
    <app-dialog
      :dialog="dialog"
      title="Confirmação"
      text="Tem certeza que deseja cancelar? Os dados inseridos serão perdidos."
      buttonCancel="Voltar"
      buttonConfirm="Confirmar"
      @clickConfirm="cancelConfirm"
      @clickCancel="cancelCancel"
    />
    <app-dialog
      :dialog="dialog2"
      title="Cadastrar & Gerar Link"
      text="Ao clicar em 'Confirmar' o usuário será cadastrado com os dados atuais e será gerado um link de acesso único através do qual o membro poderá editar seu próprio cadastro. O link será copiado automaticamente para a área de transferência."
      buttonCancel="Cancelar"
      buttonConfirm="Confirmar"
      @clickConfirm="oneTimeConfirm"
      @clickCancel="oneTimeCancel"
    />
  </section>
</template>

<style lang="scss" scoped>
.form-container,
form {
  min-width: 100%;
  width: 100%;
}

.center-element {
  width: 100%;
  display: flex;
  justify-content: center;

  button {
    margin: 0 auto;
  }
}

span {
  width: 100%;
}
</style>
