const graduationJudo = [
  {
    value: 1,
    text: "8º Kyu - Faixa Branca",
    graduation: "8 Kyu",
    color: "#d0d4d5",
    textColor: "#000000",
  },
  {
    value: 2,
    text: "7º Kyu - Faixa Cinza",
    graduation: "7º Kyu",
    color: "#d0d4d5",
    textColor: "#000000",
  },
  {
    value: 3,
    text: "6º Kyu - Faixa Azul",
    graduation: "6º Kyu",
    color: "#ccb710",
    textColor: "#000000",
  },
  {
    value: 4,
    text: "5º Kyu - Faixa Amarela",
    graduation: "5º Kyu",
    color: "#ba1818",
    textColor: "#FFFFFF",
  },
  {
    value: 5,
    text: "4º Kyu - Faixa Laranja",
    graduation: "4º Kyu",
    color: "#de5a12",
    textColor: "#FFFFFF",
  },
  {
    value: 6,
    text: "3º Kyu - Faixa Verde",
    graduation: "3º Kyu",
    color: "#179041",
    textColor: "#FFFFFF",
  },
  {
    value: 7,
    text: "2º Kyu - Faixa Roxa",
    graduation: "2º Kyu",
    color: "#4b2c4c",
    textColor: "#FFFFFF",
  },
  {
    value: 8,
    text: "1º Kyu - Faixa Marrom",
    graduation: "1º Kyu",
    color: "#4f4136",
    textColor: "#FFFFFF",
  },
  {
    value: 101,
    text: "1º Dan - Faixa Preta",
    graduation: "1º Dan",
    color: "#202020",
    textColor: "#FFFFFF",
  },
  {
    value: 102,
    text: "2º Dan - Faixa Preta",
    graduation: "2º Dan",
    color: "#202020",
    textColor: "#FFFFFF",
  },
  {
    value: 103,
    text: "3º Dan - Faixa Preta",
    graduation: "3º Dan",
    color: "#202020",
    textColor: "#FFFFFF",
  },
  {
    value: 104,
    text: "4º Dan - Faixa Preta",
    graduation: "4º Dan",
    color: "#202020",
    textColor: "#FFFFFF",
  },
  {
    value: 105,
    text: "5º Dan - Faixa Preta",
    graduation: "5º Dan",
    color: "#202020",
    textColor: "#FFFFFF",
  },
  {
    value: 106,
    text: "6º Dan - Faixa Coral",
    graduation: "6º Dan",
    color: "#202020",
    textColor: "#FFFFFF",
  },
  {
    value: 107,
    text: "7º Dan - Faixa Coral",
    graduation: "7º Dan",
    color: "#202020",
    textColor: "#FFFFFF",
  },
  {
    value: 108,
    text: "8º Dan - Faixa Coral",
    graduation: "8º Dan",
    color: "#202020",
    textColor: "#FFFFFF",
  },
  {
    value: 109,
    text: "9º Dan - Faixa Vermelha",
    graduation: "9º Dan",
    color: "#202020",
    textColor: "#FFFFFF",
  },
  {
    value: 110,
    text: "10º Dan - Faixa Vermelha",
    graduation: "10º Dan",
    color: "#202020",
    textColor: "#FFFFFF",
  },
];

export default graduationJudo;
