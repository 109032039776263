import graduationKarateJks from "@/constants/graduation/karate-jks";
import graduationJudo from "@/constants/graduation/judo";
import graduationJudoSub from "@/constants/graduation/judo-sub";

export function getGraduationList(genre) {
  switch (genre) {
    case "karate": {
      return graduationKarateJks;
    }
    case "judo": {
      return graduationJudo;
    }
    case "judo-sub": {
      return graduationJudoSub;
    }
    default: {
      return graduationKarateJks;
    }
  }
}
